<template>
  <!--  会诊专家-->
  <div class="expert">
    <div class="expert_header">
      <div>
        关键字搜索: &nbsp;
        <a-input placeholder="姓名/医院/科室/职称关键字" v-model="searchName" style="width: 220px;margin-right: 20px" @keyup.enter.native="searchBtn()"  />

        <a-button type="primary" @click="searchBtn" style="margin-left: 30px;margin-right: 10px">
          查询
        </a-button>
        <a-button @click="searchChange">
          重置
        </a-button>
      </div>
    </div>
    <div class="expert_table">
      <a-table  style="margin-bottom: 5px" :showHeader="false" :scroll="{ x: 1500}" :columns="columns"  :data-source="InfoTable" :pagination="false" rowKey="id"
      >
      <span slot="icon" slot-scope="text,item">
        <img :src="item.headImg" style="height: 50px;width: 50px" alt="">
      </span>

        <span slot="titles" slot-scope="text,item">
        <div >
          <div style="display: flex">
            <h4>{{item.name}}</h4>&nbsp;&nbsp;
            {{item.hospital}}&nbsp;&nbsp;{{item.hospitalOffice!==null?item.hospitalOffice:item.department}}&nbsp;&nbsp;{{item.job}}
          </div>
          <div style="display: flex" v-if="item.tags!==null &&item.tags!==''">
            <span  v-for="tag in item.tag">
              <a-tag>{{tag}}</a-tag>
            </span>

          </div>
          <div style="display: flex" v-else>
            <a-tag >暂无标签</a-tag>
          </div>
        </div>
      </span>
        <span slot="time" slot-scope="text,item">
        创建时间：{{item.createdTime}}

      </span>
        <span slot="open" slot-scope="text,item">
        <a-tag v-if="item.isConsultation===1" color="green">
          会诊专家
        </a-tag>
      </span>
        <span slot="operate" slot-scope="text,item,index">
        <a style="margin-right: 8px"  @click="move(item.id,'up')">上移</a>
        <a style="margin-right: 8px" @click="move(item.id,'down')">下移</a>
        <a-popconfirm
            title="确定移除?"
            ok-text="是"
            cancel-text="否"
            @confirm="delTitle(item.id)"
            @cancel="cancel">
          <a>移除</a>
        </a-popconfirm>
      </span>

      </a-table>


      <div style="display: flex;justify-content: flex-end;">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="pageChange"/>
      </div>
    </div>
  </div>
</template>

<script>

import {
  delExpert, delExpertMeet,
  getExpert, getExpertMeet,
  getMeetMemberTel,
  postExpert, postExpertMeet,
  postMeetExpert,
  putExpert,
  putExpertMember
} from "@/service/zoomMeet_api";

export default {
  name: "Expert",
  data(){
    return{
      searchName:'',
      InfoTable:[],
      columns:[
        {
          title: 'icon',
          width:'5%',
          scopedSlots: {customRender: 'icon'},
        },
        {
          title: '名称',
          width: '40%',
          scopedSlots: {customRender: 'titles'},
        },
        {
          title: '创建时间',
          scopedSlots: {customRender: 'time'},
        },
        {
          title: '专家',
          scopedSlots: {customRender: 'open'},
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: {customRender: 'operate'},
        },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },

    }
  },
  mounted() {
    this.getLogData(1)
  },
  methods:{
    async getLogData(pageNum,keyWord){
      const info = await  getExpertMeet(pageNum,keyWord)
      if (info.code===0){
        this.InfoTable=info.data.rows
        this.pagination.total=info.data.count

        this.InfoTable.forEach((value,index)=>{
          if (value.tags!==null){
            value.tag=value.tags.split(/[，；,;、]/)
          }
        })
      }
    },

    searchBtn(){
      this.getLogData(1,this.searchName)
    },
    searchChange(){
      this.searchName=''
      this.getLogData(1)
      this.pagination.current=1
    },
    pageChange(){
      this.getLogData(this.pagination.current,this.searchName)
    },
    async move(id,move){
      const data={
        moveUpDown:move
      }
      const info = await postExpertMeet(id,data)
      if (info.code===0){
        this.getLogData(this.pagination.current,this.searchName)
        this.$message.success('操作成功')
      }
    },

    async delTitle(id){
      const Info =await delExpertMeet(id)
      if (Info.code === 0){
        await this.getLogData(this.pagination.current)
        this.$message.success('操作成功')
      }else {
        this.$message.error('操作失败')
      }
    },
    cancel(){
      this.$message.warning('取消操作')
    },
  },
}
</script>

<style lang="scss" scoped>
.expert{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .expert_header{
    background: white;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 15px;
  }
  .expert_table{
    margin-top: 10px;
    padding: 15px;
    background: white;
  }
}
.uploadImg {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.upload_gutter {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 80px;
  margin-left: 5px;
}
.upload_gutter2 {
  border: #DDDDDD 2px dashed;
  width: 75px;
  height: 100px;
  margin-left: 5px;
}
</style>
